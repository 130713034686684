// eslint-disable-next-line import/no-extraneous-dependencies
const { setupWorker, rest } = require('msw')

const handlers = [
  rest.get('https://foobar.com/all-locations', (req, res, ctx) => {
    return res(
      ctx.status(200, 'OK'),
      ctx.json({
        message: 'Mocked integration OK',
      })
    )
  }),
]

const server = setupWorker(...handlers)

module.exports = { server }
