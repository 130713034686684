import React, { useEffect, useRef, useState } from 'react'
import './Main.css'
import {
  MapContainer,
  TileLayer,
  Popup,
  useMapEvent,
  Marker,
} from 'react-leaflet'
import { Icon } from 'leaflet'
import dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const icon = new Icon({
  iconUrl:
    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
})

function SetViewOnClick({ animateRef }) {
  const map = useMapEvent('click', (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: animateRef.current || false,
    })
  })

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        map
          .panTo([position.coords.latitude, position.coords.longitude])
          .setZoom(15)
      })
    }
  }, [map])

  return null
}

function Main() {
  const animateRef = useRef(true)
  const [data, setData] = useState([])

  const fetchData = async () => {
    const res = await fetch('https://karta-api.qlocxiboxen.com/getLocations')
    const apiData = await res.json()
    console.log(apiData.data)
    setData(apiData.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      {data && (
        <MapContainer
          tap={false}
          id="map"
          center={[59.34678, 18.06671]}
          zoom={12}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data.map((location) => {
            const [lat, long] = location.coordinates

            return (
              <Marker
                key={[lat, long].join('-')}
                position={[lat, long]}
                icon={icon}
              >
                <Popup>
                  <div style={{ marginBottom: '1em' }}>
                    {location.address.street}
                    <br />
                    {location.address.zip} {location.address.city}
                  </div>
                  {location.desc}
                </Popup>
              </Marker>
            )
          })}
          <SetViewOnClick animateRef={animateRef} />
        </MapContainer>
      )}
    </div>
  )
}

export default Main
